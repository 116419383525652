import { useEffect, useMemo, useState, useCallback } from "react";
import * as anchor from "@project-serum/anchor";

import styled from "styled-components";
import { Container, Snackbar } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import { PublicKey } from "@solana/web3.js";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import {
  awaitTransactionSignatureConfirmation,
  CandyMachineAccount,
  CANDY_MACHINE_PROGRAM,
  getCandyMachineState,
  mintOneToken,
} from "./candy-machine";
import { AlertState } from "./utils";
import { Header } from "./Header";
import { MintButton } from "./MintButton";
import { GatewayProvider } from "@civic/solana-gateway-react";

const ConnectButton = styled(WalletDialogButton)`
  width: 100%;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 5px;
  background: linear-gradient(180deg, #604ae5 0%, #813eee 100%);
  color: white;
  font-size: 16px;
  font-weight: bold;
`;

const MintContainer = styled.div``; // add your owns styles here

export interface HomeProps {
  candyMachineId?: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  txTimeout: number;
  rpcHost: string;
}

const Home = (props: HomeProps) => {
  const [isUserMinting, setIsUserMinting] = useState(false);
  const [candyMachine, setCandyMachine] = useState<CandyMachineAccount>();
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const rpcUrl = props.rpcHost;
  const wallet = useWallet();

  const anchorWallet = useMemo(() => {
    if (
      !wallet ||
      !wallet.publicKey ||
      !wallet.signAllTransactions ||
      !wallet.signTransaction
    ) {
      return;
    }

    return {
      publicKey: wallet.publicKey,
      signAllTransactions: wallet.signAllTransactions,
      signTransaction: wallet.signTransaction,
    } as anchor.Wallet;
  }, [wallet]);

  const refreshCandyMachineState = useCallback(async () => {
    if (!anchorWallet) {
      return;
    }

    if (props.candyMachineId) {
      try {
        const cndy = await getCandyMachineState(
          anchorWallet,
          props.candyMachineId,
          props.connection
        );
        setCandyMachine(cndy);
      } catch (e) {
        console.log("There was a problem fetching Candy Machine state");
        console.log(e);
      }
    }
  }, [anchorWallet, props.candyMachineId, props.connection]);

  const onMint = async () => {
    try {
      setIsUserMinting(true);
      document.getElementById("#identity")?.click();
      if (wallet.connected && candyMachine?.program && wallet.publicKey) {
        const mintTxId = (
          await mintOneToken(candyMachine, wallet.publicKey)
        )[0];

        let status: any = { err: true };
        if (mintTxId) {
          status = await awaitTransactionSignatureConfirmation(
            mintTxId,
            props.txTimeout,
            props.connection,
            true
          );
        }

        if (status && !status.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (!error.message) {
          message = "Transaction Timeout! Please try again.";
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          window.location.reload();
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      setIsUserMinting(false);
    }
  };

  useEffect(() => {
    refreshCandyMachineState();
  }, [
    anchorWallet,
    props.candyMachineId,
    props.connection,
    refreshCandyMachineState,
  ]);

  return (
    <div>
      <header className="bg-theme-inverted shadow-inner">
        <nav className="text-purple-100 bg-purple-500 py-4 shadow">
          <div className="container mx-auto flex justify-between items-center px-4 sm:px-0">
            <div className="font-title font-semibold text-lg">
              FlowersEnergyPT NFT
            </div>
            <div className="flex space-x-4">
              <div>
                <a className="underline" href="#about">
                  About
                </a>
              </div>
              {/* <div><a className="underline" href="#roadmap">Roadmap</a></div> */}
            </div>
          </div>
        </nav>
        <div className="grid grid-cols-1 flex justify-center items-center max-w-4xl py-16 mx-auto gap-4 sm:grid-cols-2">
          <div className="pt-4 px-4 pb-12 mx-auto bg-white shadow-lg rounded-lg order-last sm:order-first">
            <img
              className="block max-w-xs lg:max-w-sm h-auto"
              src="/images/hero.gif"
              alt="Hero"
            />
          </div>
          <div className="flex flex-col justify-between items-center text-center px-4 xs:px-0">
            <div>
              <h2 className="font-title font-semibold text-3xl sm:text-4xl md:text-5xl text-purple-100 mb-6">
                FlowersEnergyPT NFT
              </h2>
              <p className="text-xl">
                An NFT collection of curated beautiful flowers photographs on
                the Solana blockchain.
              </p>
              <div className="flex justify-center space-x-4 mx-auto my-8">
                <a
                  href="https://twitter.com/FlowEngPT_NFT"
                  className="inline-flex items-center px-6 py-3 border border-transparent text-sm font-medium rounded-full shadow-sm bg-pink-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  style={{ color: "#ffffff" }}
                >
                  Twitter
                </a>
                <a
                  href="https://discord.gg/m7uMh7yZ"
                  className="inline-flex items-center px-6 py-3 border border-transparent text-sm font-medium rounded-full shadow-sm bg-pink-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  style={{ color: "#ffffff" }}
                >
                  Discord
                </a>
              </div>
            </div>
            <Container
              maxWidth="xs"
              className="relative"
              style={{ display: "none" }}
            >
              <Paper
                style={{
                  padding: 24,
                  backgroundColor: "#151A1F",
                  borderRadius: 6,
                }}
              >
                {!wallet.connected ? (
                  <ConnectButton>Connect Wallet</ConnectButton>
                ) : (
                  <>
                    <Header candyMachine={candyMachine} />
                    <MintContainer>
                      {candyMachine?.state.isActive &&
                      candyMachine?.state.gatekeeper &&
                      wallet.publicKey &&
                      wallet.signTransaction ? (
                        <GatewayProvider
                          wallet={{
                            publicKey:
                              wallet.publicKey ||
                              new PublicKey(CANDY_MACHINE_PROGRAM),
                            //@ts-ignore
                            signTransaction: wallet.signTransaction,
                          }}
                          gatekeeperNetwork={
                            candyMachine?.state?.gatekeeper?.gatekeeperNetwork
                          }
                          clusterUrl={rpcUrl}
                          options={{ autoShowModal: false }}
                        >
                          <MintButton
                            candyMachine={candyMachine}
                            isMinting={isUserMinting}
                            onMint={onMint}
                          />
                        </GatewayProvider>
                      ) : (
                        <MintButton
                          candyMachine={candyMachine}
                          isMinting={isUserMinting}
                          onMint={onMint}
                        />
                      )}
                    </MintContainer>
                  </>
                )}
              </Paper>
            </Container>
          </div>
        </div>
      </header>

      <div className="bg-white py-16 shadow-inner shadow">
        <div className="max-w-4xl mx-auto">
          <div className="px-4 xs:px-0">
            <h1 className="font-title font-semibold text-3xl md:text-4xl text-black mb-6">
              About
            </h1>
            <div className="grid grid-cols-2 gap-4 prose max-w-none">
              <div>
                <h3>What is the FlowersEnergyPT NFT project?</h3>
                <p>
                  FlowersEnergyPT NFT is an NFT collection of curated beautiful
                  flowers photographs on the Solana blockchain.
                </p>
              </div>
              <div>
                <h3>When will the project launch?</h3>
                <p>
                  The FlowersEnergyPT NFTs will be launched on 8th February
                  2022.
                </p>
              </div>
              <div>
                <h3>How much will the mint price be?</h3>
                <p>
                  The mint price for the first edition is 0.3 SOL. Follow our{" "}
                  <a href="https://twitter.com/FlowEngPT_NFT">Twitter</a> and{" "}
                  <a href="https://discord.gg/m7uMh7yZ">Discord</a> for the
                  latest updates.
                </p>
              </div>
              <div>
                <h3>How do I purchase a FlowersEnergyPT NFT?</h3>
                <p>
                  Sales will start on 8th February 2022. When you connect your
                  wallet, you will see a mint button enabled when the countdown
                  is over (the mint button will be available when the launch
                  date is announced).
                </p>
              </div>
              <div>
                <h3>How will the collection be organized?</h3>
                <p>
                  The photos NFTs will be released in several editions over
                  time. Each edition will contain 25 collectible items. A total
                  of 10 editions are planned out for now. All photos are
                  original work. Each edition will include photos with different
                  styles: Original, edited, painted and Black&White styles. Some
                  styles will be exclusive to specific editions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="text-purple-100 text-center bg-theme-inverted py-8">
        &copy; 2022 FlowersEnergyPT
      </footer>
      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Home;
